<div class="overlay-minimal">
    <div class="overlay-container">
        <h1 class="eon-bold">
            {{ showFeatures ? ('popovers.optInPopover.titleWithFeatures' | translate) : ('popovers.optInPopover.titleWithoutFeatures' | translate) }}
        </h1>
        <ng-container *ngIf="showFeatures; else alternate">
            <p class="eon-regular">
                {{ 'popovers.optInPopover.featuresDescription' | translate }}
            </p>
            <div class="feature-list">
                <div *ngFor="let feature of features" class="feature-list-item">
                    <div class="feature-icon"
                         [style.background-image]="createImageUrl(feature)"></div>
                    <div class="feature-description">
                        <div><strong class="m-b-m font-bold">{{feature.title}}</strong></div>
                        <div class="font-regular">{{feature.text}}</div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #alternate>
            <p class="font-regular">
                {{ 'popovers.optInPopover.alternateDescriptionPart1' | translate }}
            </p>
            <p class="font-regular">
                {{ 'popovers.optInPopover.alternateDescriptionPart2' | translate }}
            </p>
        </ng-template>
        <div class="center-contents m-a-m">
            <button class="eon-button-new" (click)="close(true)">
                {{ 'popovers.optInPopover.allowOnlineMode' | translate }}
            </button>
        </div>
        <div class="center-contents">
            <a href="javascript:" target="_self"
               class="eon-link-new"
               (click)="close()">
                {{ 'common.logout' | translate }}
            </a>
        </div>
    </div>
</div>
