import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {duration} from 'moment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

export const getMonthName = (idx: number, translate: TranslateService): Observable<string> => {
    const date: Date = new Date();
    date.setMonth(date.getMonth() - idx);
    const monthIndex = date.getMonth();

    return translate.get('screens.dashboard.months').pipe(
        map((translatedMonths: string[]) => translatedMonths[monthIndex])
    );
};

export const getWeek = (date): number => {
    const now: any = new Date(date);
    now.setHours(0, 0, 0);
    now.setDate(now.getDate() + 4 - (now.getDay() || 7));

    const start: any = new Date(now.getFullYear(), 0, 1);

    return Math.ceil((((now.valueOf() - start.valueOf()) / 86400000) + 1) / 7);
};

export const determineDurationPassed = (since: any, translate: TranslateService): string => {
    const date = moment(new Date(since));
    const dur = duration(moment().diff(date));
    let s = '';
    const secs = dur.asSeconds();

    if (secs < 60) {
        translate.get('screens.dashboard.live.duration.justNow').subscribe((res: string) => {
            s = res;
        });
    } else if (secs >= 60 && secs < 3600) {
        const minutes = `${dur.minutes()} ${secs === 60 ?
            translate.instant('screens.dashboard.live.duration.minute') :
            translate.instant('screens.dashboard.live.duration.minutes')}`;
        translate.get('screens.dashboard.live.duration.since',
            {value: minutes}).subscribe((res: string) => {
            s = res;
        });
    } else if (secs >= 3600 && secs < 86400) {
        const hours = `${dur.hours()} ${secs === 60 ?
            translate.instant('screens.dashboard.live.duration.hour') :
            translate.instant('screens.dashboard.live.duration.hours')}`;
        translate.get('screens.dashboard.live.duration.since',
            {value: hours}).subscribe((res: string) => {
            s = res;
        });
    } else {
        const formattedDate = date.format('DD.MM.YYYY');
        translate.get('screens.dashboard.live.duration.since',
            {value: formattedDate}).subscribe((res: string) => {
            s = res;
        });
    }

    return s;
};

