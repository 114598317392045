import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {constants} from '../shared/constants/constants';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';
import {
    DisaggregationResponseData
} from '../shared/interfaces/plain-responses/disaggregation-response.interface';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class DisaggregationService extends BaseService {

    private updateRate = 10000;

    private timerSub: Subscription = null;

    private disaggregationData: any = null;


    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService,
                private translate: TranslateService) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
        if (this.timerSub) {
            this.timerSub.unsubscribe();
        }

        delete this.timerSub;
    }


    getDisaggregationDataForAppliancesTile(): Observable<any> {
        let data: Observable<any> = null;
        if (this.disaggregationData) {
            data = of(this.disaggregationData);
        } else {
            data = this.getDisaggregationHistoryNew(0);
        }
        return data.pipe(
            tap(receivedData => this.disaggregationData = receivedData),
            mergeMap(receivedData => this.mapForAppliancesTile(receivedData))
        );
    }


    /**
     * Get disaggregation data with a defined month offset
     * @param offset
     */
    getDisaggregationHistoryByOffset(offset: number): Observable<DisaggregationResponseData> {
        const currentLang = this.translate.currentLang;

        const method = moment()
            .locale(currentLang)
            .subtract(offset, 'months')
            .startOf('month')
            .format('YYYY/MM');
        return of(this.application.isDemoMode()).pipe(
            mergeMap(isDemoMode => {
                if (isDemoMode) {
                    return this.getDisaggregationDemoData().pipe(
                        map(data => {
                            return data[data.length - offset - 1];
                        })
                    );
                }
                return this.requestDisaggregationHistory(method);
            }),
            // tap(data => console.log('data', data)),
        );
    }


    /**
     * Get disaggregation data with a defined month offset
     * @param offset
     * @param month
     */
    getDisaggregationHistoryForMonth(offset: number, month: number): Observable<any> {
        const currentLang = this.translate.currentLang;

        let method = moment()
            .locale(currentLang)
            .subtract(offset, 'years')
            .startOf('month')
            .format('YYYY');
        method += '/' + month;
        return of(this.application.isDemoMode()).pipe(
            mergeMap(isDemoMode => {
                if (isDemoMode) {
                    return this.getDisaggregationDemoData().pipe(
                        map(data => {
                            const ds = data.reverse();
                            // const currentMonth = new Date().getMonth();
                            const monthIndex = month + (offset * 12) - 1;
                            return ds[monthIndex];
                        })
                    );
                }
                return this.requestDisaggregationHistory(method);
            })
        );
    }


    /**
     * Get disaggregation data with a defined month offset
     * @param offset
     * @param month
     * @private
     */
    private getDisaggregationHistoryNew(offset: number, month = 0): Observable<any> {
        const date = new Date();
        date.setMonth(date.getMonth() - offset, 1);

        let method = date.getFullYear() + '/' + (date.getMonth() + 1);
        if (month > 0) {
            method = date.getFullYear() + '/' + month;
        }

        let url = this.API_BASE_URL + constants.api.routes.disaggregation.history + '/' + method;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.disaggregationHistory}.json`;
        }

        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            map((disaggregationData: any[]) => {
                if (this.application.isDemoMode()) {
                    if (month > 0) {
                        console.log('Not implemented yet');
                    } else {
                        return disaggregationData.last();
                    }
                } else {
                    return disaggregationData;
                }
            }),
            catchError((error: any) => this.handleError(error))
        );
    }


    private getDisaggregationHistory(method: string, mockData = -1): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.disaggregation.history + '/' + method;
        if (mockData === 0) {
            url = `assets/data/demo/${constants.demo.files.disaggregationHistory}.json`;
        }
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => {
                return this.mapDefault(res);
            }),
            catchError((error: any) => this.handleError(error))
        );
    }


    /**
     * Main method to get disaggregation history
     * @param method
     * @private
     */
    private requestDisaggregationHistory(method: string): Observable<DisaggregationResponseData> {
        const url = this.API_BASE_URL + constants.api.routes.disaggregation.history + '/' + method;
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => {
                return this.mapDefault(res);
            }),
            catchError((error: any) => this.handleError(error)),
        );
    }


    /**
     * Get disaggregation demo data
     * @private
     */
    private getDisaggregationDemoData(): Observable<DisaggregationResponseData[]> {
        const url = `assets/data/demo/${constants.demo.files.disaggregationHistory}.json`;
        return this.http.get(url).pipe(
            map(res => this.mapDefault(res))
        );
    }


    /**
     * Map response for appliances tile
     * @param response
     * @private
     */
    private mapForAppliancesTile(response: any): Observable<any> {
        try {
            return of(response.electricity.used_budget.categories);
        } catch (e) {
            return throwError(e);
        }
    }
}
