import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {AppRoutingModule, routedComponents} from './app-routing.module';

import {ChartModule} from 'angular-highcharts';

import {TextMaskModule} from 'angular2-text-mask';
import {ToastrModule} from 'ngx-toastr';

import {Angulartics2Module} from 'angulartics2';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import {LiveTileComponent} from './tiles/live/live-tile/live-tile.component';
import {LiveDetailsComponent} from './tiles/live/live-details/live-details.component';
import {
    AppliancesTileComponent
} from './tiles/appliances/appliances-tile/appliances-tile.component';
import {
    AppliancesDetailsComponent
} from './tiles/appliances/appliances-detail/appliances-detail.component';
import {TodayTileComponent} from './tiles/today/today-tile/today-tile.component';
import {TodayDetailsComponent} from './tiles/today/today-details/today-details.component';
import {CompareTileComponent} from './tiles/compare/comparison-tile/comparison-tile.component';
import {
    ComparisonDetailsComponent
} from './tiles/compare/comparison-details/comparison-details.component';
import {MeterTileComponent} from './tiles/meter/meter-tile/meter-tile.component';
import {MeterDetailsComponent} from './tiles/meter/meter-details/meter-details.component';
import {FinanceTileComponent} from './tiles/finance/finance-tile/finance-tile.component';
import {FinanceDetailsComponent} from './tiles/finance/finance-details/finance-details.component';
import {
    ConsumptionAlertTileComponent
} from './tiles/consumption-alert/consumption-alert-tile/consumption-alert-tile.component';
import {
    ConsumptionAlertDetailsComponent
} from './tiles/consumption-alert/consumption-alert-details/consumption-alert-detail.component';
import {ApiService} from './services/api.service';
import {MockDataService} from './services/mock-data.service';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from '@angular/common/http';
import {
    ConnectionIndicatorComponent
} from './components/connection-indicator/connection-indicator.component';
import {HeightControlDirective} from './directives/height-control.directive';
import {UserService} from './services/user.service';
import {
    PowerCheckerTileComponent
} from './tiles/powerchecker/powerchecker-tile/powerchecker-tile.component';
import {
    PowerCheckerDetailsComponent
} from './tiles/powerchecker/powerchecker-details/powerchecker-details.component';
import {PowerSwitchComponent} from './tiles/powerchecker/power-switch/power-switch.component';
import {MovableDirective} from './directives/movable.directive';
import {NguCarouselModule} from '@ngu/carousel';
import {NgxHmCarouselModule} from 'ngx-hm-carousel';
import {PowercheckerStateService} from './services/powerchecker-state.service';
import {
    RemainingTimeIndicatorComponent
} from './components/remaining-time-indicator/remaining-time-indicator.component';
import player from 'lottie-web';
import {MvpTileComponent} from './tiles/mvp/mvp-tile/mvp-tile.component';
import {MvpDetailsComponent} from './tiles/mvp/mvp-details/mvp-details.component';
import {PortalModule} from '@angular/cdk/portal';
import {TariffDataEntryComponent} from './popovers/tariff-data-entry/tariff-data-entry.component';
import {PopoverComponent} from './popovers/popover/popover.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {TariffHistoryComponent} from './popovers/tariff-history/tariff-history.component';
import {ChangelogComponent} from './popovers/changelog/changelog.component';
import {LiveChartComponent} from './charts/live-chart/live-chart.component';
import {MenuComponent} from './components/menu/menu.component';
import {AddTileComponent} from './popovers/add-tile/add-tile.component';
import {SortTilesComponent} from './popovers/sort-tiles/sort-tiles.component';
import {
    BinaryConfirmPopoverComponent
} from './popovers/binary-confirm-popover/binary-confirm-popover.component';
import {MatchHeightDirective} from './directives/match-height.directive';
import {MatchWidthDirective} from './directives/match-width.directive';
import {HalfCircleDirective} from './directives/half-circle.directive';
import {
    ConsumptionAlertChartComponent
} from './charts/consumption-alert-chart/consumption-alert-chart.component';
import {MatchParentHeightDirective} from './directives/match-parent-height.directive';
import {ChangeEmailComponent} from './popovers/change-email/change-email.component';
import {ResetPasswordComponent} from './popovers/reset-password/reset-password.component';
import {CountSelectorComponent} from './components/count-selector/count-selector.component';
import {TabSelectorComponent} from './components/tab-selector/tab-selector.component';
import {InfoPopoverComponent} from './popovers/info-popover/info-popover.component';
import {
    EnergySaverControlComponent
} from './popovers/energy-saver-control/energy-saver-control.component';
import {EonSliderComponent} from './components/eon-slider/eon-slider.component';
import {
    EnergySaverBannerComponent
} from './components/energy-saver-banner/energy-saver-banner.component';
import {BannerComponent} from './components/banner/banner.component';
import {
    ValueEntryPopoverComponent
} from './popovers/value-entry-component/value-entry-popover.component';
import {UpdatePopoverComponent} from './popovers/update-popover/update-popover.component';
import {OptInPopoverComponent} from './popovers/opt-in-popover/opt-in-popover.component';
import {BatteryLoadComponent} from './components/battery-load/battery-load.component';
import {MfaCodePopoverComponent} from './popovers/mfa-code-popover/mfa-code-popover.component';
import {MfaLoginPopoverComponent} from './popovers/mfa-login-popover/mfa-login-popover.component';
import {
    MfaCodeEntryPopoverComponent
} from './popovers/mfa-code-entry-popover/mfa-code-entry-popover.component';
import {
    DatePickerButtonComponent
} from './components/date-picker-button/date-picker-button.component';
import {HelpComponent} from './help/help.component';
import {ToggleComponent} from './components/toggle/toggle.component';
import {ApplianceChartComponent} from './charts/appliance-chart/appliance-chart.component';
import {
    NilmDevicePopoverComponent
} from './popovers/nilm-device-popover/nilm-device-popover.component';
import {LottieModule} from 'ngx-lottie';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {EonToggleComponent} from './eon-toggle/eon-toggle.component';
import {AuthInterceptorService} from './services/auth-interceptor.service';
import {
    ComparisonChartComponent
} from './charts/static-comparison-chart/comparison-chart.component';
import {
    HouseholdComparisonTileComponent
} from './tiles/household-comparison/household-comparison-tile/household-comparison-tile.component';
import {LargeIntPipe} from './shared/pipes/large-int.pipe';
import {
    HouseholdComparisonChartComponent
} from './charts/household-comparison-chart/household-comparison-chart.component';
import {
    HouseholdComparisonDetailComponent
} from './tiles/household-comparison/household-comparison-detail/household-comparison-detail.component';
import {
    HouseholdComparisonFilterFormComponent
} from './components/household-comparison/household-comparison-filter-form/household-comparison-filter-form.component';
import {
    HouseholdComparisonTotalComponent
} from './components/household-comparison/household-comparison-total/household-comparison-total.component';
import {
    HouseholdComparisonDetailSidebarComponent
} from './components/household-comparison/household-comparison-detail-sidebar/household-comparison-detail-sidebar.component';
import {
    HouseholdComparisonFilterDisplayComponent
} from './components/household-comparison/household-comparison-filter-display/household-comparison-filter-display.component';
import {
    HouseholdComparisonAppliancesComponent
} from './components/household-comparison/household-comparison-appliances/household-comparison-appliances.component';
import {
    HouseholdComparisonRankComponent
} from './components/household-comparison/household-comparison-rank/household-comparison-rank.component';
import {
    HouseholdComparisonRankRowComponent
} from './components/household-comparison/household-comparison-rank-row/household-comparison-rank-row.component';
import {
    MeterValueDisplayComponent
} from './components/meter-value-display/meter-value-display.component';
import {PaddedMeterValuePipe} from './shared/pipes/padded-meter-value.pipe';
import {DetailBannerComponent} from './components/detail-banner/detail-banner.component';
import {
    ApplianceDetailDataDisplayComponent
} from './components/appliances/appliance-detail-data-display/appliance-detail-data-display.component';
import {
    AppliancesRetrainingComponent
} from './components/appliances/appliances-retraining/appliances-retraining.component';
import { TodayTileStatsComponent } from './components/today/today-tile-stats/today-tile-stats.component';
import {TodayValueFormatPipe} from './shared/pipes/today-value-format.pipe';
import {NameOfDayPipe} from './shared/pipes/name-of-day.pipe';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { SupportModalComponent } from './components/support-modal/support-modal.component';
import {LanguageModalComponent} from './components/language-modal/language-modal.component';
import {MatRadioModule} from '@angular/material/radio';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {
    ComparisonChartBaseComponent
} from './charts/comparison-chart-base/comparison-chart-base.component';
import {
    RealTimeAlertDetailsComponent
} from './tiles/real-time-alert/real-time-alert-details/real-time-alert-details.component';
import {
    RealTimeAlertTileComponent
} from './tiles/real-time-alert/real-time-alert-tile/real-time-alert-tile.component';
import {NgOptimizedImage} from '@angular/common';
import {
    RealTimeAlertChartComponent
} from './charts/real-time-alert-chart/real-time-alert-chart.component';


export function playerFactory() {
    return player;
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ChartModule,
        TextMaskModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            maxOpened: 2,
            positionClass: 'toast-bottom-right'
        }),
        Angulartics2Module.forRoot(),
        HttpClientModule,
        NguCarouselModule,
        NgxHmCarouselModule,
        LottieModule.forRoot({
            player: playerFactory
        }),
        PortalModule,
        OverlayModule,
        ReactiveFormsModule,
        DragDropModule,
        MatDialogModule,
        MatButtonModule,
        MatRadioModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        NgOptimizedImage
    ],
    declarations: [
        AppComponent,
        routedComponents,
        LiveTileComponent,
        LiveDetailsComponent,
        AppliancesTileComponent,
        AppliancesDetailsComponent,
        TodayTileComponent,
        TodayDetailsComponent,
        CompareTileComponent,
        ComparisonDetailsComponent,
        MeterTileComponent,
        MeterDetailsComponent,
        FinanceTileComponent,
        FinanceDetailsComponent,
        ConsumptionAlertTileComponent,
        ConsumptionAlertDetailsComponent,
        ConnectionIndicatorComponent,
        HeightControlDirective,
        PowerCheckerTileComponent,
        PowerCheckerDetailsComponent,
        PowerSwitchComponent,
        MovableDirective,
        RemainingTimeIndicatorComponent,
        MvpTileComponent,
        MvpDetailsComponent,
        TariffDataEntryComponent,
        PopoverComponent,
        TariffHistoryComponent,
        ChangelogComponent,
        LiveChartComponent,
        MenuComponent,
        AddTileComponent,
        SortTilesComponent,
        BinaryConfirmPopoverComponent,
        MatchHeightDirective,
        MatchWidthDirective,
        HalfCircleDirective,
        ConsumptionAlertChartComponent,
        MatchParentHeightDirective,
        ChangeEmailComponent,
        ResetPasswordComponent,
        CountSelectorComponent,
        TabSelectorComponent,
        InfoPopoverComponent,
        EnergySaverControlComponent,
        EonSliderComponent,
        EnergySaverBannerComponent,
        BannerComponent,
        ValueEntryPopoverComponent,
        UpdatePopoverComponent,
        OptInPopoverComponent,
        BatteryLoadComponent,
        MfaCodePopoverComponent,
        MfaCodeEntryPopoverComponent,
        MfaLoginPopoverComponent,
        DatePickerButtonComponent,
        HelpComponent,
        NilmDevicePopoverComponent,
        ToggleComponent,
        ApplianceChartComponent,
        EonToggleComponent,
        ComparisonChartComponent,
        HouseholdComparisonChartComponent,
        HouseholdComparisonTileComponent,
        LargeIntPipe,
        HouseholdComparisonDetailComponent,
        HouseholdComparisonTotalComponent,
        HouseholdComparisonDetailSidebarComponent,
        HouseholdComparisonFilterFormComponent,
        HouseholdComparisonFilterDisplayComponent,
        HouseholdComparisonAppliancesComponent,
        HouseholdComparisonRankComponent,
        HouseholdComparisonRankRowComponent,
        PaddedMeterValuePipe,
        MeterValueDisplayComponent,
        DetailBannerComponent,
        ApplianceDetailDataDisplayComponent,
        AppliancesRetrainingComponent,
        ComparisonChartBaseComponent,
        TodayTileStatsComponent,
        TodayValueFormatPipe,
        NameOfDayPipe,
        ErrorDialogComponent,
        SupportModalComponent,
        LanguageModalComponent,
        RealTimeAlertDetailsComponent,
        RealTimeAlertTileComponent,
        RealTimeAlertChartComponent
    ],
    providers: [
        ApiService,
        MockDataService,
        UserService,
        PowercheckerStateService,
        {
            provide: 'googleTagManagerId', useValue: 'GTM-NDBX2F4'
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        }
    ],
    schemas: [
        // CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
