import {Injectable} from '@angular/core';
import {NilmService} from '../nilm.service';
import {DisaggregationService} from '../disaggregation.service';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ViewState} from '../../shared/enums/view-state.enum';
import * as moment from 'moment';
import {DataProviderBaseService} from './data-provider-base.service';
import {
    AlignedApplianceCategoryData,
    ApplianceCategories,
    ApplianceDiagramSeriesData
} from '../../shared/interfaces/appliances-categories.interfaces';
import {
    ApplianceDiagramSeriesDataWrapper,
    AppliancesTileData,
    initialAppliancesTileData
} from '../../shared/interfaces/appliances-tile-data.interfaces';
import {TranslateService} from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class AppliancesTileDataProviderService extends DataProviderBaseService {

    private readonly colors = ['#1ea2b1', '#56b9c5', '#616161', '#747475'];
    private nilmStatusDataSub: Subscription | null = null;

    appliancesTileData$ =
        new BehaviorSubject<AppliancesTileData>(initialAppliancesTileData);


    constructor(
        private disaggregateService: DisaggregationService,
        private nilmService: NilmService,
        private translate: TranslateService
    ) {
        super();
    }


    destroy() {
        super.destroy();
        if (this.nilmStatusDataSub) {
            this.nilmStatusDataSub.unsubscribe();
            this.nilmStatusDataSub = null;
        }
    }


    /**
     * Requests the electrical appliances data from the disaggregation service
     */
    setupAppliancesTileDataRetrieval() {
        let nilmDataAvailable = false;
        let nilmProfileComplete = false;
        let nilmRetrainingInProgress = false;
        if (this.nilmStatusDataSub) {
            console.log('Nilm Status sub already exists');
            return;
        }
        this.appliancesTileData$.next({...initialAppliancesTileData, viewState: ViewState.LOADING});
        this.nilmStatusDataSub = this.nilmService.onNewNilmStatusUpdate$.pipe(
            mergeMap(newNilmStatusData => {
                if (newNilmStatusData) {
                    nilmDataAvailable = true;
                }
                return this.requestElectricalAppliancesData$();
            }),
            mergeMap((categories: AlignedApplianceCategoryData[]) => {
                const mappedCategories = categories.map(el => el.name);
                if (nilmDataAvailable) {
                    nilmProfileComplete = !this.nilmService.isProfileComplete(mappedCategories);
                    nilmRetrainingInProgress = this.nilmService.hasAppliancesInRetraining();
                }
                return of(categories);
            }),
            mergeMap(alignedCategories => {
                return this.generateDiagramSeries$(alignedCategories, nilmDataAvailable).pipe(
                    mergeMap(series =>
                        this.translate.get('screens.dashboard.months').pipe(
                            map(translatedMonths => {
                                const currentMonthIndex = moment().month();
                                const currentMonth = translatedMonths[currentMonthIndex];

                                return {
                                    series,
                                    nilmDataAvailable,
                                    nilmProfileComplete,
                                    nilmRetrainingInProgress,
                                    currentMonth,
                                    viewState: ViewState.SUCCESS,
                                } as AppliancesTileData;
                            })
                        )
                    )
                );
            }),
            catchError((error) => {
                console.log('Error in appliances tile data retrieval', error);
                return of({
                    ...initialAppliancesTileData,
                    viewState: ViewState.ERROR,
                });
            })
        ).subscribe({
            next: (data) => {
                this.appliancesTileData$.next(data);
            }
        });

        this.translate.onLangChange.subscribe(() => {
            this.translate.get('screens.dashboard.months').subscribe(translatedMonths => {
                const currentMonthIndex = moment().month();
                this.appliancesTileData$.next({
                    ...this.appliancesTileData$.value,
                    currentMonth: translatedMonths[currentMonthIndex]
                });
            });
        });
    }


    /**
     * Generates a diagram series by extracting the categories with the highest values
     * Other categories are summed up and displayed as 'Others'
     * @param alignedCategories
     * @param nilmDataAvailable
     */
    private generateDiagramSeries$(
        alignedCategories: AlignedApplianceCategoryData[],
        nilmDataAvailable: boolean
    ): Observable<ApplianceDiagramSeriesDataWrapper> {
        return new Observable((observer) => {
            try {
                const series: ApplianceDiagramSeriesData[] = [];
                const customNilmValues: boolean[] = [];

                // process categories with the highest values
                for (const appliance of alignedCategories.slice(0, 3)) {
                    let categoryComplete = false;
                    let categoryInRetraining = false;
                    if (nilmDataAvailable) {
                        categoryComplete = this.nilmService.nilmCategoryIsComplete(
                            appliance.name.toLowerCase()
                        );
                        categoryInRetraining = this.nilmService.nilmCategoryIsRetraining(
                            appliance.name.toLowerCase()
                        );
                        customNilmValues.push(!categoryComplete || categoryInRetraining);
                    }
                    series.push({
                        name: appliance.name,
                        y: appliance.usage,
                        color: null,
                        sliced: false
                    });
                }

                // process others category
                let other = 0;
                const otherCustomNilmValues: boolean[] = [];
                for (const appliance of alignedCategories.slice(3)) {
                    other += appliance.usage;
                    let categoryComplete = false;
                    let categoryInRetraining = false;
                    if (nilmDataAvailable) {
                        categoryComplete = this.nilmService.nilmCategoryIsComplete(
                            appliance.name.toLowerCase()
                        );
                        categoryInRetraining = this.nilmService.nilmCategoryIsRetraining(
                            appliance.name.toLowerCase()
                        );
                        otherCustomNilmValues.push(!categoryComplete || categoryInRetraining);
                    }
                }
                if (other > 0) {
                    series.push({
                        name: 'Other',
                        y: other,
                        color: null,
                        sliced: false
                    });
                    const nilmStateOthers = otherCustomNilmValues.every(el => el === true);
                    customNilmValues.push(nilmStateOthers);
                }

                // assign colors
                let colorCounter = 0;
                for (const element of series) {
                    element.color = this.colors[colorCounter];
                    ++colorCounter;
                }

                observer.next({series, nilm: customNilmValues});
            } catch (error) {
                observer.error(error);
            }
        });
    }


    /**
     * Align the raw categories - ultimately this function remaps the response to an array-like
     * @param categories
     */
    private alignCategoryData$(categories: ApplianceCategories)
        : Observable<AlignedApplianceCategoryData[]> {
        return new Observable((observer) => {
            try {
                const tempData: AlignedApplianceCategoryData[] = [];
                for (const appliance of Object.keys(categories)) {
                    if (categories[appliance].usage > 0) {
                        tempData.push({
                            name: appliance,
                            usage: categories[appliance].usage
                        });
                    }
                }
                tempData.sort((a, b) => b.usage - a.usage);
                observer.next(tempData);
            } catch (error) {
                observer.error(error);
            }
        });
    }


    /**
     * Request the electrical appliances data from the disaggregation service
     * @private
     */
    private requestElectricalAppliancesData$(): Observable<any> {
        return this.disaggregateService.getDisaggregationDataForAppliancesTile().pipe(
            mergeMap((categories: ApplianceCategories) => this.alignCategoryData$(categories)),
        );
    }
}


